<template>
  <div class="manage-floor-plan-comments">
    <div class="container fluid">
      <fd-tabs v-model="tab">
        <!-- Pending -->
        <fd-tab-window name="Pending" card>
          <manage-comment-table
            status="Pending Approval"
          ></manage-comment-table>
        </fd-tab-window>
        <!-- Approved -->
        <fd-tab-window name="Approved">
          <manage-comment-table status="Approval"></manage-comment-table>
        </fd-tab-window>
        <!-- Rejected -->
        <fd-tab-window name="Rejected">
          <manage-comment-table status="Reject"></manage-comment-table>
        </fd-tab-window>
      </fd-tabs>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    ManageCommentTable: () =>
      import("@/components/ManageFloorPlanComments/ManageCommentTable")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      tab: ""
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {},

    openCommentDetail(event) {
      //
    }
  }
};
</script>

<style lang="scss">
.manage-floor-plan-comments {
  img.avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
    display: inline-block;
  }
}
</style>
